const getUsageOptionsByMetricTypes = (metricTypes) => {
  const usageOptions = {};

  for (const [key, value] of Object.entries(metricTypes)) {
    usageOptions[key] = value;
  }

  return usageOptions;
};

const getUsageOptionsByFilteredCF = (usageOptions, filteredCF, item = false) => {
  const usageOptionsKeys = Object.keys(usageOptions);

  const filteredUsageOptions = {};

  // Filter the Conversion Factors based on the selected options in the item to remove unnessary options in the dropdowns
  filteredCF = filteredCF?.filter(
    (cf) =>
      (!item?.finallocation || cf?.finallocation === item?.finallocation) &&
      (!item?.originlocation || cf?.originlocation === item?.originlocation) &&
      (!item?.factorprovider || cf?.factorprovider === item?.factorprovider) &&
      (!item?.price || cf?.price === item?.price)
  );
  filteredCF?.forEach((cf) => {
    //looping through the keys of the options object with production,vehicle,fuel,etc
    usageOptionsKeys.forEach((usageOpt) => {
      const usageOptionId = cf[usageOpt];
      // Check if the usageOption exists in the filtered Conversion Factors
      const foundOptionObj = usageOptionId && usageOptions?.[usageOpt]?.find((item) => item?.id === usageOptionId);

      if (usageOptionId && foundOptionObj) {
        // Usage Option ID exists in the filtered CF -> It's a valid option
        if (!filteredUsageOptions[usageOpt]) {
          filteredUsageOptions[usageOpt] = [];
        } else if (filteredUsageOptions[usageOpt].find((item) => item?.id === usageOptionId)) {
          // If the option already exists in the filteredUsageOptions, don't add it again
          return;
        }
        filteredUsageOptions[usageOpt].push(foundOptionObj);
      }
    });
  });

  return filteredUsageOptions;
};

export { getUsageOptionsByFilteredCF, getUsageOptionsByMetricTypes };
