import _ from 'lodash';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { CircularProgress } from '@mui/material';
import React, { useState, useEffect, Fragment } from 'react';
import ConfirmationModal from './ConfirmationModal';
import CustomSelectWithTooltip from './CustomSelectWithTooltip';
import { Actions as ADMINACTIONS } from '../../store/actions/adminActions';
import { Actions as USAGEACTIONS } from '../../store/actions/usageActions';
import { getUsageOptionsByFilteredCF } from '../UsageData/utils/UsageUtils';
import { addYears, differenceInDays, formatISO, isLeapYear } from 'date-fns';
import { AddButton, CalendarBtn, AddUsageInputStyles } from '../../styledComponents/GlobalStyle';
import { parseDateToTz, isValidDateFormat, RemoveLeadingZeroes } from '../utils/GlobalFunctions';

export const itemToOptions = (item) => ({
  value: item?.id,
  label: item?.name || item?.priceType,
  description: item?.description,
  // for sub entities we need the metric and calc_market property
  metric: item?.metric || null,
  fuel_mix: item?.calc_market || null,
});

const AddEditUsage = ({
  item,
  metric,
  options,
  currency,
  prevDates,
  showLoader,
  gridSources,
  adminPortal,
  cyfDataAdmin,
  getUsageById,
  usageDetails,
  orderPriority,
  saveUsageData,
  solarConsumed,
  editUsageModal,
  waterTreatment,
  usageOptionsOK,
  solarSubentities,
  getAllItemsFromDB,
  conversionFactors,
  getFilteredFactors,
  showUsageDataModal,
  electricitySources,
  organisationCurrency,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [showFuelImport, setShowFuelImport] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [invalidUsageDate, setInvalidUsageDate] = useState(false);
  const [hasFuelMix, setHasFuelMix] = useState(item?.fuel_mix ? true : false);
  const [localGridMix, setLocalGridMix] = useState(item?.fuel_mix || { 599324: '100' });
  const [localTextState, setLocalTextState] = useState({
    userLabel: null,
    usage: null,
    waterTreatment: null,
    solarConsumed: null,
  });

  const localGridChange = (value, name) => {
    if (!hasFuelMix) setLocalGridMix({ 599324: '100' });
    else {
      const prevMix = { ...localGridMix };
      prevMix[name] = parseFloat(value) <= 0 ? '0' : RemoveLeadingZeroes(value);
      // Delete residual if we have a fuel mix.
      if (Object.keys(prevMix).length > 1) delete prevMix['599324'];
      setLocalGridMix(prevMix);
    }
  };

  const fuelMixSum = Number(
    Object.values(localGridMix)
      .reduce((a, b) => Number(a) + Number(b), 0)
      .toFixed(11)
  );

  //is a facility, subentity, usage>0, usageOptions set, and gridmix adding up to 100, if there is one?
  const canSubmitUsage =
    item?.dateEnd &&
    item?.facility &&
    usageOptionsOK &&
    item?.subentity &&
    item?.dateStart &&
    !invalidUsageDate &&
    !!conversionFactors?.length > 0 &&
    Number(localTextState?.usage) > 0 &&
    (showFuelImport ? fuelMixSum === 100 : true) &&
    new Date(item?.dateStart) <= new Date(item?.dateEnd);

  useEffect(() => {
    if (options?.subentity?.value?.value && item?.dateStart) {
      const subentityId = options.subentity.value.value;
      const subentityPossibleFuelMix = options.subentity.value.fuel_mix || false;
      const metric = options.metric?.value?.value;
      if (metric) {
        getFilteredFactors(
          formatISO(new Date(item.dateStart)),
          subentityId,
          subentityPossibleFuelMix,
          metric,
          adminPortal ? item.organisation : null
        );
      }
    }
  }, [
    adminPortal,
    item?.dateEnd,
    item?.dateStart,
    item?.organisation,
    getFilteredFactors,
    options?.metric?.value?.value,
    options?.subentity?.value?.value,
    options?.subentity?.value?.fuel_mix,
  ]);

  useEffect(() => {
    if (item?.dateStart && item?.dateEnd) {
      const dateDiffInDays = differenceInDays(new Date(item?.dateEnd), new Date(item?.dateStart));
      const usageStartYear = new Date(item?.dateStart).getFullYear();
      // If usage date start falls after february check if usage date end year is a leap year
      // Otherwise we check if usage date start year is a leap year
      const checkForEndYear = formatISO(new Date(item?.dateStart)) >= formatISO(new Date(usageStartYear, 2, 1));
      const leapYear = checkForEndYear
        ? isLeapYear(addYears(new Date(item?.dateStart), 1))
        : isLeapYear(new Date(item?.dateStart));
      const maxUsageSpanDays = leapYear ? 365 : 364;

      // Date Span cannot be higher than 364 days
      if (dateDiffInDays > maxUsageSpanDays) setInvalidUsageDate(true);
      else setInvalidUsageDate(false);
    }
  }, [item?.dateStart, item?.dateEnd]);

  useEffect(() => {
    if (gridSources && !electricitySources) getAllItemsFromDB('electricitySources');
  }, [gridSources, electricitySources, getAllItemsFromDB]);

  useEffect(() => setHasFuelMix(gridSources?.length > 0), [gridSources?.length]);

  useEffect(() => {
    if (item?.id || item?.copy) {
      setLocalTextState((state) => ({
        ...state,
        userLabel: item?.userLabel || null,
        rawUsage: item?.copy ? null : item?.rawUsage || null,
        usage: item?.copy ? '' : item?.rawUsage || item?.usage || '',
      }));
      if (item?.fuel_mix) setLocalGridMix(item?.fuel_mix);
      // For edit mode, if the usage has fuel mix different Residual show the grid mix inputs
      if (item?.fuel_mix && item?.fuel_mix?.['599324'] !== '100') setShowFuelImport(true);
    }
  }, [item]);

  useEffect(() => {
    if (metric?.isSpend && !item?.currency && organisationCurrency) {
      if (item?.id || item?.copy) {
        editUsageModal(item?.rawUsageCurrency || metric?.currencyId || organisationCurrency.id, { name: 'currency' });
      } else editUsageModal(organisationCurrency?.id, { name: 'currency' });

      editUsageModal(true, { name: 'isSpend' });
    }
  });

  return (
    <div className='modal' style={{ display: 'block', background: 'rgba(0,0,0,.5)' }}>
      <div className='modal-dialog' style={{ height: '85vh', fontSize: '12px' }}>
        <div className='modal-content' style={{ background: 'transparent' }}>
          <div className='modal-header'>
            <button onClick={() => showUsageDataModal(false, null)} type='button' className='close'>
              x
            </button>
            <h4 className='modal-title'>{item?.id ? 'Edit' : 'Add'} Usage Data</h4>
          </div>
          <div
            id='modal-body'
            className='modal-body'
            style={{ overflow: 'auto', background: 'transparent', height: '85vh', padding: 0 }}>
            <div
              style={{
                padding: 15,
                overflow: 'visible',
                background: 'white',
                position: 'relative',
                borderBottomLeftRadius: '6px',
                borderBottomRightRadius: '6px',
              }}>
              {showLoader && (
                <div
                  style={{
                    top: '0',
                    left: '0',
                    zIndex: '999',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    position: 'absolute',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <CircularProgress color='inherit' />
                </div>
              )}
              <div
                style={{
                  gap: '15px',
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  transition: 'filter 0.3s ease-in-out',
                  filter: showLoader ? 'blur(1.5px)' : 'none',
                }}>
                {/* Facility */}
                <div style={{ gridColumn: 'span 2 / span 2' }}>
                  <label>
                    Facility <em>*</em>
                  </label>
                  <CustomSelectWithTooltip
                    name={'facility'}
                    isClearable={true}
                    options={options?.facility?.values}
                    value={options?.facility?.value || null}
                    onChange={(s, e) => editUsageModal(s?.value, e)}
                  />
                </div>

                {/* Start date */}
                <div>
                  <label style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Start date *
                    {prevDates?.dateStart && (
                      <button
                        style={{
                          border: 0,
                          color: '#1f9b37',
                          paddingRight: '0',
                          background: 'inherit',
                          textDecoration: 'underline',
                        }}
                        onClick={() => editUsageModal(prevDates, { name: 'prevDates' })}>
                        Use Previous Dates
                      </button>
                    )}
                  </label>

                  <label className='input-group date datepicker'>
                    <DatePicker
                      selectsStart
                      showYearDropdown
                      showMonthDropdown
                      isClearable={true}
                      maxDate={new Date()}
                      scrollableYearDropdown
                      dateFormat='dd/MM/yyyy'
                      yearDropdownItemNumber={5}
                      popperPlacement='bottom-end'
                      className='form-control startDate'
                      endDate={item?.dateEnd && new Date(item?.dateEnd)}
                      startDate={item?.dateStart && new Date(item?.dateStart)}
                      selected={(item?.dateStart && parseDateToTz(item?.dateStart, 'yyyy-MM-dd')) || null}
                      onChange={(date, e) => {
                        const dateInput = e?.target?.value;

                        if (!date) editUsageModal(date, { name: 'dateStart' });
                        else if (dateInput && !isValidDateFormat(dateInput)) return console.log('Invalid date format');
                        else {
                          editUsageModal(formatISO(date), { name: 'dateStart' });
                          if (!item?.dateEnd || new Date(item?.dateEnd) < date)
                            editUsageModal(formatISO(date), { name: 'dateEnd' });
                        }
                      }}
                    />
                    <span className='input-group-btn'>
                      <CalendarBtn className='btn btn-primary date-set'>
                        <i className='fa fa-calendar' />
                      </CalendarBtn>
                    </span>
                  </label>
                </div>

                {/* End date */}
                <div>
                  <label>End date *</label>

                  <label className='input-group date datepicker'>
                    <DatePicker
                      selectsEnd
                      showYearDropdown
                      showMonthDropdown
                      isClearable={true}
                      scrollableYearDropdown
                      dateFormat='dd/MM/yyyy'
                      yearDropdownItemNumber={5}
                      popperPlacement='bottom-end'
                      className='form-control endDate'
                      endDate={item?.dateEnd && new Date(item?.dateEnd)}
                      minDate={item?.dateStart && new Date(item?.dateStart)}
                      startDate={item?.dateStart && new Date(item?.dateStart)}
                      onChange={(date, event) => {
                        const inputDate = event?.target?.value;

                        if (!date && !inputDate) {
                          editUsageModal(null, { name: 'dateEnd' });
                        } else if (inputDate && isValidDateFormat(inputDate)) {
                          const parsedDate = parseDateToTz(inputDate, 'dd/MM/yyyy');
                          editUsageModal(formatISO(parsedDate), { name: 'dateEnd' });
                        } else if (date) {
                          editUsageModal(formatISO(date), { name: 'dateEnd' });
                        }
                      }}
                      selected={item?.dateEnd ? new Date(item.dateEnd) : null}
                    />
                    <span className='input-group-btn'>
                      <CalendarBtn className='btn btn-primary date-set'>
                        <i className='fa fa-calendar' />
                      </CalendarBtn>
                    </span>
                  </label>
                </div>

                {/* Invalid date */}
                {invalidUsageDate && (
                  <div style={{ gridColumn: 'span 2 / span 2', margin: 'auto' }}>
                    <label style={{ color: 'red', fontWeight: 'bold' }}>
                      <i
                        className='fa-solid fa-triangle-exclamation'
                        style={{
                          fontSize: 20,
                          marginRight: 10,
                          verticalAlign: 'sub',
                        }}
                      />
                      Your date span cannot exceed 1 year.
                    </label>
                  </div>
                )}

                {/* Custom top-level category */}
                <div style={{ gridColumn: 'span 2 / span 2' }}>
                  <label style={{ display: 'inline-flex' }}>
                    Custom top-level category
                    <Tooltip
                      color='white'
                      background='#333'
                      direction='right'
                      forceDirection={true}
                      styles={{ marginLeft: '0.5rem' }}
                      tipContentClassName='addUsageData-tooltip-content'
                      content={'Optional customised top-level category name.' || ''}>
                      <i className='fa fa-question-circle' style={{ cursor: 'pointer' }} />
                    </Tooltip>
                  </label>
                  <input
                    name='userLabel'
                    style={AddUsageInputStyles}
                    value={localTextState?.userLabel || ''}
                    onChange={(e) => setLocalTextState((state) => ({ ...state, userLabel: e?.target?.value || null }))}
                  />
                </div>

                {/* Usage type */}
                <div style={{ gridColumn: 'span 2 / span 2' }}>
                  <label>
                    Usage type <em>*</em>
                  </label>
                  <CustomSelectWithTooltip
                    zIndex={'auto'}
                    name={'entity'}
                    isClearable={true}
                    options={options?.entity?.values}
                    value={options?.entity?.value || null}
                    onChange={(s, e) => editUsageModal(s?.value, e)}
                  />
                </div>

                {/* Fields based on options */}
                {item?.dateStart &&
                  _.sortBy(Object.entries(options), ([key]) => orderPriority[key] || Infinity)
                    .filter(
                      ([key]) =>
                        options[key]?.values?.length > 0 &&
                        !['facility', 'entity'].includes(key) &&
                        !(key === 'metric' && options?.metric?.values?.length === 1)
                    )
                    .map(([key, option]) => {
                      return (
                        <div key={key} style={{ gridColumn: 'span 2 / span 2' }}>
                          <label>
                            {option.title} <em>*</em>
                          </label>
                          <CustomSelectWithTooltip
                            name={key}
                            item={item}
                            zIndex={'auto'}
                            isClearable={true}
                            options={option.values}
                            value={option.value || null}
                            editUsageModal={editUsageModal}
                            onChange={(s, e) => editUsageModal(key === 'subentity' ? s : s?.value, e)}
                          />
                        </div>
                      );
                    })}

                {/* Usage number */}
                {item?.dateStart && item?.metric && (
                  <div style={{ gridColumn: 'span 2 / span 2' }}>
                    <label style={{ display: 'inline-flex' }}>
                      Usage in&nbsp;
                      {metric?.isSpend ? `${currency?.symbol} (${currency?.isoCode})` : options.metric?.value?.label}&nbsp;
                      <em>*</em>
                      <Tooltip
                        color='white'
                        background='#333'
                        direction='right'
                        forceDirection={true}
                        styles={{ marginLeft: '0.5rem' }}
                        tipContentClassName='addUsageData-tooltip-content'
                        content={
                          metric?.isSpend
                            ? `Amount of ${currency?.symbol} (${currency?.isoCode}) you spent on usage type.`
                            : options?.metric?.value?.description || ''
                        }>
                        <i className='fa fa-question-circle' style={{ cursor: 'pointer' }} />
                      </Tooltip>
                    </label>
                    <NumberFormat
                      name={'numberName'}
                      inputMode='numeric'
                      allowNegative={false}
                      className='form-control'
                      value={localTextState.usage}
                      onValueChange={(e) => setLocalTextState((state) => ({ ...state, usage: e.floatValue || null }))}
                    />
                  </div>
                )}

                {/* Water / Solar usage */}
                {(waterTreatment || solarConsumed) && (
                  <div style={{ gridColumn: 'span 2 / span 2' }}>
                    <label style={{ display: 'inline-flex' }}>
                      {waterTreatment ? 'Water Treatment in' : 'Solar Consumed in'} {options?.metric?.value?.label}&nbsp;
                      <em>*</em>
                      <Tooltip
                        color='white'
                        background='#333'
                        direction='right'
                        forceDirection={true}
                        styles={{ marginLeft: '0.5rem' }}
                        tipContentClassName='addUsageData-tooltip-content'
                        content={options?.metric?.value?.description || ''}>
                        <i className='fa fa-question-circle' style={{ cursor: 'pointer' }} />
                      </Tooltip>
                    </label>
                    <NumberFormat
                      inputMode='numeric'
                      allowNegative={false}
                      className='form-control'
                      name={waterTreatment ? 'waterTreatment' : 'solarConsumed'}
                      value={localTextState?.[waterTreatment ? 'waterTreatment' : 'solarConsumed']}
                      onValueChange={(values) =>
                        setLocalTextState((state) => ({
                          ...state,
                          [waterTreatment ? 'waterTreatment' : 'solarConsumed']: values.floatValue || null,
                        }))
                      }
                    />
                  </div>
                )}

                {/* Energy Fuel Mix */}
                {item?.dateStart && item?.entity && item?.subentity && gridSources?.length > 0 && (
                  <>
                    {/* Grid Toggle */}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gridColumn: 'span 2 / span 2',
                        justifyContent: 'space-between',
                      }}>
                      <label style={{ display: 'inline-flex', marginBottom: '0' }}>
                        Do you have your supplier's fuel mix?
                        <Tooltip
                          color='white'
                          zIndex={99999}
                          className='tooltip-fuelMix-wrapper'
                          tipContentClassName='tooltip-fuelMix-content'
                          content={
                            "You will find your energy providers' fuel mix via their website or in your utility bills. Enter the percentage of the energy source without % into each fuel type, even if it is 0. For example, if 100% renewables, it would be entered as Biomass 0 Gas 0 Other 0 Coal 0 Nuclear 0 Renewable 100. The total sum of the percentage numbers must be equal to 100." ||
                            ''
                          }>
                          <i className='fa fa-question-circle' style={{ cursor: 'pointer' }} />
                        </Tooltip>
                      </label>

                      <div style={{ gap: '1rem', display: 'flex' }}>
                        <button
                          disabled={showFuelImport}
                          className='btn btn-success'
                          onClick={() => {
                            setLocalGridMix(Object.fromEntries(gridSources.map((str) => [str, '0'])));
                            setShowFuelImport(true);
                          }}>
                          Yes
                        </button>
                        <button
                          disabled={!showFuelImport}
                          className='btn btn-warning'
                          onClick={() => {
                            setLocalGridMix({ 599324: '100' });
                            setShowFuelImport(false);
                          }}>
                          No
                        </button>
                      </div>
                    </div>

                    {/* Grid */}
                    {showFuelImport &&
                      gridSources?.map((gs, index) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <label style={{ maxWidth: '50%' }}> {_.find(electricitySources, { id: gs })?.source}</label>
                          <NumberFormat
                            name={gs}
                            defaultValue={0}
                            inputMode='numeric'
                            allowNegative={false}
                            style={{ width: '50%' }}
                            value={localGridMix?.[gs]}
                            isAllowed={(values) => {
                              if (!values.value) return true;
                              const { floatValue } = values;
                              return floatValue < 101;
                            }}
                            className='form-control'
                            onChange={(e) => localGridChange(e.target.value || 0, gs)}
                          />
                        </div>
                      ))}

                    {showFuelImport && localGridMix?.['599324'] !== '100' && fuelMixSum !== 100 && (
                      <div style={{ gridColumn: 'span 2 / span 2', margin: 'auto' }}>
                        <label style={{ color: 'red', verticalAlign: 'sub', fontWeight: 'bold' }}>
                          <i className='fa-solid fa-triangle-exclamation' style={{ marginRight: 10, fontSize: 20 }} />
                          Grid mix does not make up 100%
                        </label>
                      </div>
                    )}
                  </>
                )}

                {/* Editing solar */}
                {item?.id && solarSubentities?.includes(item?.subentity) && (
                  <div
                    className='alert alert-info'
                    style={{ color: '#130384', marginBottom: '0', gridColumn: 'span 2 / span 2' }}>
                    <i className='fa fa-exclamation-triangle' aria-hidden='true' />
                    &nbsp; When editing Solar usage, ensure that your corresponding data for the same time periods fits this
                    formula:
                    <p style={{ margin: 'auto', width: 'fit-content' }}>
                      Solar Generated kWh = Solar Consumed kWh + Solar Exported kWh
                    </p>
                  </div>
                )}

                {/* No carbon factor */}
                {item?.dateStart && conversionFactors?.length === 0 && (
                  <div style={{ gridColumn: 'span 2 / span 2', margin: 'auto' }}>
                    <label style={{ color: 'red', fontWeight: 'bold' }}>
                      <i
                        className='fa-solid fa-triangle-exclamation'
                        style={{
                          fontSize: 20,
                          marginRight: 10,
                          verticalAlign: 'sub',
                        }}
                      />
                      Sorry, we don't have a carbon factor for that year, please contact for help.
                    </label>
                  </div>
                )}

                {/* Submit button */}
                <div style={{ gridColumn: 'span 2 / span 2', margin: 'auto' }}>
                  <AddButton
                    id='submit-usage'
                    style={{ cursor: !canSubmitUsage && 'not-allowed' }}
                    className={`btn btn-${canSubmitUsage ? 'success' : 'warning'}`}
                    onClick={() => {
                      if (canSubmitUsage) {
                        saveUsageData(
                          [
                            {
                              ...item,
                              usage: localTextState?.usage,
                              waterTreatment: localTextState?.waterTreatment,
                              solarConsumed: localTextState?.solarConsumed,
                              userLabel: localTextState?.userLabel,
                              fuel_mix: (hasFuelMix && localGridMix) || null,
                            },
                          ],
                          adminPortal
                        );
                        setHasFuelMix(false);
                        setLocalGridMix(false);
                        setLocalTextState({
                          usage: null,
                          userLabel: null,
                          waterTreatment: null,
                          solarConsumed: null,
                        });
                        setShowConfirmModal(true);
                      }
                    }}>
                    {canSubmitUsage ? `${item?.id ? 'Edit' : 'Add'} Data` : 'Data Incomplete'}
                  </AddButton>
                </div>

                {/* Admin info */}
                {cyfDataAdmin && item?.id && (
                  <div style={{ gridColumn: 'span 2 / span 2', margin: 'auto' }}>
                    <label
                      onClick={() => {
                        if (!usageDetails) getUsageById(item?.id);
                        setShowMore(!showMore);
                      }}
                      style={{ cursor: 'pointer', userSelect: 'none', fontWeight: 'bold' }}>
                      {showMore ? 'hide' : 'see more'}
                    </label>
                  </div>
                )}
                {showMore && usageDetails && (
                  <div style={{ overflowWrap: 'break-word', gridColumn: 'span 2 / span 2' }}>
                    <p style={{ fontWeight: 'bold' }}>Modified Date: {usageDetails?.modifiedDate}</p>
                    {['location', 'market'].map((el, key) => (
                      <div key={key}>
                        <p style={{ fontWeight: 'bold' }}>{el.charAt(0).toUpperCase() + el.slice(1)}:</p>
                        {['calendarYear', 'reportingYear'].map((elem, index) => (
                          <div key={index}>
                            <p style={{ fontWeight: 'bold' }}>{elem.charAt(0).toUpperCase() + elem.slice(1)}</p>
                            {Object.keys(usageDetails?.[el]?.[elem]).map((x, i) => (
                              <ol key={i}>
                                {x} : {JSON.stringify(usageDetails?.[el]?.[elem]?.[x])}
                              </ol>
                            ))}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <ConfirmationModal showConfirmModal={showConfirmModal} setShowConfirmModal={setShowConfirmModal} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const metrics = state?.metric?.list;
  const profile = state?.profile.details;
  const { item, adminPortal } = state?.usageDataModal || {};
  const usageTypeDetails = state?.admin?.adminPortal?.usageTypeDetails;
  const { entities, subentities, usageOptions, conversionFactors, filteredCF_UsageOpt } = state || {};
  const facilities = adminPortal
    ? _.filter(state?.admin?.adminPortal?.facilities, { organisationId: item.organisation })
    : state?.facility?.list;

  const currencies = state?.admin?.adminPortal?.currencies;
  const currency = currencies?.find((c) => c.id === item?.currency);
  const metric = metrics?.find((m) => m.id === item?.metric);
  const organisationCurrency = currencies?.find((c) => c.id === state?.organisation?.details?.currency);

  const solarSubentities = ['229591', '233875', '233408'];
  const solarConsumed = !item?.id && item?.metric === '4385' && item?.subentity === '229591';
  const waterTreatment = !item?.id && item?.metric === '4389' && item?.entity === '151938' && item?.subentity !== '185362';

  const orderPriority = {
    entity: 1,
    subentity: 2,
    metric: 3,
    finallocation: 4,
    originlocation: 5,
    factorprovider: 6,
    price: 7,
    currency: 8,
  };

  const options = {
    facility: {
      values: _.sortBy(facilities?.map(itemToOptions), ['label']),
      value: item?.facility && _.find(facilities?.map(itemToOptions), { value: item.facility }),
    },
    entity: {
      values: _.sortBy(entities?.map(itemToOptions), ['label']),
      value: item?.entity && _.find(entities?.map(itemToOptions), { value: item?.entity }),
    },
    subentity: {
      values: item?.entity
        ? _.sortBy(subentities?.filter((s) => s.entity.includes(item?.entity) && !s?.hideInUI)?.map(itemToOptions), [
            'label',
          ])
        : [],
      title: 'Usage Sub Type',
      value: item?.subentity && _.find(subentities?.map(itemToOptions), { value: item?.subentity }),
    },
  };

  options.metric = {
    values: item?.subentity
      ? _.sortBy(metrics?.filter((m) => options?.subentity?.value?.metric?.includes(m.id))?.map(itemToOptions), ['label'])
      : [],
    title: 'Usage In',
    value: item?.metric && _.find(metrics?.map(itemToOptions), { value: item?.metric }),
  };

  // if (isSpend && !item?.currency) options.currency.value = { value: item?.rawUsageCurrency || organisationCurrency.id };
  if ((organisationCurrency, options))
    if (usageOptions && item?.metric) {
      // Add dynamic usage options
      Object.keys(usageOptions).forEach((usageOpt, index, arr) => {
        const { displayName } = usageTypeDetails?.find((ut) => ut.type === usageOpt) || {};
        const label = displayName ? displayName : usageOpt.charAt(0).toUpperCase() + usageOpt.slice(1) + ' type';

        if (index === 0) {
          // Show immediately the first one
          options[usageOpt] = {
            values: _.sortBy(usageOptions[usageOpt]?.map(itemToOptions), ['label']),
            title: label,
            value: item?.[usageOpt] && _.find(usageOptions[usageOpt]?.map(itemToOptions), { value: item?.[usageOpt] }),
          };
        } else if (item[arr[index - 1]]) {
          for (index; index <= arr.length - 1; index++) {
            // As soon as we select a usage option, we filter the CF that match that usage option and the above usage combo
            // Filter the usage options to just show the ones that match the filtered CF
            const filteredUsageOpt_CF = getUsageOptionsByFilteredCF(usageOptions, filteredCF_UsageOpt, item);
            options[usageOpt] = {
              values: _.sortBy(filteredUsageOpt_CF[usageOpt]?.map(itemToOptions), ['label']),
              title: label,
              value: item?.[usageOpt] && _.find(usageOptions[usageOpt]?.map(itemToOptions), { value: item?.[usageOpt] }),
            };
          }
        }
      });
    }

  options.currency = {
    values: metric?.isSpend //Only show the currency field if metric is Spend
      ? _.sortBy(
          currencies?.filter((x) => x.hideInUI !== true).map((x) => ({ label: `${x.symbol} (${x.isoCode})`, value: x.id })),
          ['label']
        )
      : [],
    title: 'Usage Data Input Currency',
    value:
      item?.currency &&
      _.find(
        currencies?.filter((x) => x.hideInUI !== true).map((x) => ({ label: `${x.symbol} (${x.isoCode})`, value: x.id })),
        { value: item?.currency }
      ),
  };

  // Check that each dynamic option is correct
  let usageOptionsOK = true;
  if (usageOptions && Object.keys(usageOptions).length > 0) {
    const filteredUsageOpt_CF = getUsageOptionsByFilteredCF(usageOptions, filteredCF_UsageOpt);
    Object.keys(Object.keys(filteredUsageOpt_CF).length > 0 ? filteredUsageOpt_CF : usageOptions).every((ut) => item?.[ut]);
  }

  return {
    item,
    metric,
    options,
    currency,
    adminPortal,
    solarConsumed,
    orderPriority,
    waterTreatment,
    usageOptionsOK,
    solarSubentities,
    conversionFactors,
    organisationCurrency,
    showLoader: state.showLoader,
    gridSources: state?.gridSources,
    prevDates: state?.usageDataModal?.prevDates,
    usageDetails: state?.usageDataModal?.usageDetails,
    cyfDataAdmin: profile?.cyfAdmin && profile.canEditAdminPortal,
    electricitySources: state?.admin?.adminPortal?.electricitySources,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUsageById: (id) => dispatch(USAGEACTIONS.getUsageById(id)),
  getAllItemsFromDB: (table) => dispatch(ADMINACTIONS.getAllItemsFromDB(table)),
  showUsageDataModal: (status, item) => dispatch(USAGEACTIONS.showUsageDataModal(status, item)),
  editUsageModal: (selectedInput, event) => dispatch(USAGEACTIONS.editUsageModal(selectedInput, event)),
  saveUsageData: (usageData, adminPortal) => dispatch(USAGEACTIONS.saveUsageData(usageData, adminPortal)),
  getFilteredFactors: (year, subentity, gm, metric, organisationId) =>
    dispatch(USAGEACTIONS.getFilteredFactors(year, subentity, gm, metric, organisationId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditUsage);
